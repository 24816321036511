/**
 * @fileoverview
 * В этот файл добавляем полифиллы, которые нужны НЕ поддерживающим ES2015+ браузерам.
 * Полифиллы для новых версий браузеров добавляем сюда: {@link res/js/polyfillsModern.js}
 */

var stringPrototype = String.prototype;

/* Template tag styling support for IE */
document.createElement('template');
/**
 * rAF
 */
(function() {
    var lastTime = 0;

    if (!window.requestAnimationFrame || !window.cancelAnimationFrame) {
        window.requestAnimationFrame = function(callback, element) {
            var currTime = Date.now(),
                timeToCall = Math.max(0, 16 - (currTime - lastTime)),
                id = window.setTimeout(function() {
                    callback(currTime + timeToCall)
                }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

        window.cancelAnimationFrame = clearTimeout;
    }
})();

if (window.SVGElement) {
    // В IE11 у SVGElement нет classList и parentElement
    if (!("classList" in SVGElement.prototype)) {
        (function() {
            // helpers
            var regExp = function(name) {
                return new RegExp('(^| )'+ name +'( |$)');
            };
            var forEach = function(list, fn, scope) {
                for (var i = 0; i < list.length; i++) {
                    fn.call(scope, list[i]);
                }
            };

            // class list object with basic methods
            function ClassList(element) {
                this.element = element;
            }

            ClassList.prototype = {
                add: function() {
                    forEach(arguments, function(name) {
                        if (!this.contains(name)) {
                            this.element.className += ' '+ name;
                        }
                    }, this);
                },
                remove: function() {
                    forEach(arguments, function(name) {
                        this.element.className =
                            this.element.className.replace(regExp(name), '');
                    }, this);
                },
                toggle: function(name) {
                    return this.contains(name)
                        ? (this.remove(name), false) : (this.add(name), true);
                },
                contains: function(name) {
                    return regExp(name).test(this.element.className);
                },
                // bonus..
                replace: function(oldName, newName) {
                    this.remove(oldName), this.add(newName);
                }
            };

            Object.defineProperty(SVGElement.prototype, 'classList', {
                get: function() {
                    return new ClassList(this);
                }
            });

        })();
    }

    if (!("parentElement" in SVGElement.prototype)) {
        Object.defineProperty(SVGElement.prototype, 'parentElement', {
            get: function() {
                return this.parentNode;
            }
        });
    }
}

(function() {
    var testElement = document.createElement("_");
    // Check add/remove with more than one argument (IE/Trident, Firefox < 26, Chrome < 28)
    testElement.classList.add('c1', 'c2');
    if (!testElement.classList.contains('c2')) {
        var createMethod = function(method) {
            var original = DOMTokenList.prototype[method];

            DOMTokenList.prototype[method] = function(token) {
                var i, len = arguments.length;

                for (i = 0; i < len; i++) {
                    token = arguments[i];
                    original.call(this, token);
                }
            };
        };
        createMethod('add');
        createMethod('remove');
    }
    // Check toggle with 2 parameters (IE/Trident, Firefox < 24, Chrome < 24)
    testElement.classList.toggle('c3', false);
    if (testElement.classList.contains('c3')) {
        var _toggle = DOMTokenList.prototype.toggle;

        DOMTokenList.prototype.toggle = function(token, force) {
            if (1 in arguments && !this.contains(token) === !force) {
                return force;
            } else {
                return _toggle.call(this, token);
            }
        };
    }
}());

/* getBoundingClientRect for old Amigo(?) browser */
if (!window.getBoundingClientRect) {
    window.getBoundingClientRect = function() {
        return {
            top: 0,
            left: 0,
            bottom: window.innerHeight || window.clientHeight,
            right: window.innerWidth || window.clientWidth
        };
    };
}

/**
 * performance.now() by Paul Irish
 * https://gist.github.com/paulirish/5438650
 */
(function(){

    if ("performance" in window == false) {
        window.performance = {};
    }

    if ("now" in window.performance == false){

        var nowOffset = Date.now();

        if (performance.timing && performance.timing.navigationStart){
            nowOffset = performance.timing.navigationStart
        }

        window.performance.now = function now(){
            return Date.now() - nowOffset;
        }
    }
})();

/**
 * CustomEvent constructor for IE
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
(function () {

    if (typeof window.CustomEvent === "function") {
        return false;
    }

    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
})();

/**
 * Array.prototype.find
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find#Polyfill
 */
if (!Array.prototype.find) {
    Array.prototype.find = function(predicate) {
        'use strict';
        if (this == null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
}

/**
 * Array.prototype.findIndex
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex#Polyfill
 */
if (!Array.prototype.findIndex) {
    Array.prototype.findIndex = function(predicate) {
        'use strict';
        if (this == null) {
            throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return i;
            }
        }
        return -1;
    };
}

/**
 * Element.matches
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
 */
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;
        };
}

/**
 * Object.assign
 * https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
 */
if (!Object.assign) {
    Object.defineProperty(Object, 'assign', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function(target, firstSource) {
            'use strict';
            if (target === undefined || target === null) {
                throw new TypeError('Cannot convert first argument to object');
            }

            var to = Object(target);
            for (var i = 1; i < arguments.length; i++) {
                var nextSource = arguments[i];
                if (nextSource === undefined || nextSource === null) {
                    continue;
                }

                var keysArray = Object.keys(Object(nextSource));
                for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                    var nextKey = keysArray[nextIndex];
                    var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                    if (desc !== undefined && desc.enumerable) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
            return to;
        }
    });
}

/**
 * Object.values
 * https://stackoverflow.com/questions/7306669/how-to-get-all-properties-values-of-a-javascript-object-without-knowing-the-key/16643074#16643074
 */
if (!Object.values) {
	Object.defineProperty(Object, 'values', {
        enumerable: false,
        configurable: true,
        writable: true,
		value: function(target) {
            'use strict';
			return Object.keys(target).map(function(key) {
				return target[key];
			});
		}
	});
}

/**
 * String.prototype.endsWith
 * https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
 */
if (!stringPrototype.endsWith) {
    Object.defineProperty(stringPrototype, 'endsWith', {
        value: function(searchString, position) {
            var subjectString = this.toString();
            if (position === undefined || position > subjectString.length) {
                position = subjectString.length;
            }
            position -= searchString.length;
            var lastIndex = subjectString.indexOf(searchString, position);
            return lastIndex !== -1 && lastIndex === position;
        }
    });
}

if (typeof stringPrototype.startsWith !== 'function') {
    stringPrototype.startsWith = function (text, pos) {
        return this.indexOf(text, pos = pos || 0) === pos;
    };
}

if (!stringPrototype.includes) {
    stringPrototype.includes = function (search, start) {
        if (typeof start !== 'number') {
            start = 0;
        }
        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}

if (!String.fromCodePoint) {
    (function (stringFromCharCode) {
        var fromCodePoint = function () {
            var codeUnits = [], codeLen = 0, result = '';
            for (var index = 0, len = arguments.length; index !== len; ++index) {
                var codePoint = +arguments[index];
                // correctly handles all cases including `NaN`, `-Infinity`, `+Infinity`
                // The surrounding `!(...)` is required to correctly handle `NaN` cases
                // The (codePoint>>>0) === codePoint clause handles decimals and negatives
                if (!(codePoint < 0x10FFFF && (codePoint >>> 0) === codePoint))
                    throw RangeError('Invalid code point: ' + codePoint);
                if (codePoint <= 0xFFFF) { // BMP code point
                    codeLen = codeUnits.push(codePoint);
                } else { // Astral code point; split in surrogate halves
                    // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
                    codePoint -= 0x10000;
                    codeLen = codeUnits.push(
                        (codePoint >> 10) + 0xD800,  // highSurrogate
                        (codePoint % 0x400) + 0xDC00 // lowSurrogate
                    );
                }
                if (codeLen >= 0x3fff) {
                    result += stringFromCharCode.apply(null, codeUnits);
                    codeUnits.length = 0;
                }
            }
            return result + stringFromCharCode.apply(null, codeUnits);
        };
        try { // IE 8 only supports `Object.defineProperty` on DOM elements
            Object.defineProperty(String, 'fromCodePoint', {
                'value': fromCodePoint, 'configurable': true, 'writable': true
            });
        } catch (e) {
            String.fromCodePoint = fromCodePoint;
        }
    }(String.fromCharCode));
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/**
 * https://developer.mozilla.org/ru/docs/Web/API/HTMLCanvasElement/toBlob
 */
if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
            var canvas = this;
            setTimeout(function() {

                var binStr = atob( canvas.toDataURL(type, quality).split(',')[1] ),
                    len = binStr.length,
                    arr = new Uint8Array(len);

                for (var i = 0; i < len; i++ ) {
                    arr[i] = binStr.charCodeAt(i);
                }

                callback( new Blob( [arr], {type: type || 'image/png'} ) );

            });
        }
    });
}

/**
 * https://developer.mozilla.org/ru/docs/Web/API/Element/closest
 */
(function(ELEMENT) {
    ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
    ELEMENT.closest = ELEMENT.closest || function closest(selector) {
        if (!this) return null;
        if (this.matches(selector)) return this;
        if (!this.parentElement) {return null}
        else return this.parentElement.closest(selector)
    };
}(Element.prototype));

(function () {
    /**
     * https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Array/from#polyfill
     * Добавляем в условие имплементации полифилла отсутствие нативного Мапа / Сета,
     * так как нативный Array.from не сможет преобразовать в массив нашу версию Мапы / Сета
    */
    if (!Array.from || isMapShouldBePolyfilled() || isSetShouldBePolyfilled()) {
        Array.from = (function () {
            var toStr = Object.prototype.toString;
            var isCallable = function (fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function (value) {
                var number = Number(value);
                if (isNaN(number)) { return 0; }
                if (number === 0 || !isFinite(number)) { return number; }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function (value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
            };

            // The length property of the from method is 1.
            return function from(arrayLike/*, mapFn, thisArg */) {
                if (arrayLike instanceof Set) {
                    arrayLike = arrayLike.values();
                }

                if (arrayLike instanceof Map) {
                    arrayLike = arrayLike.entries();
                }

                // Convert iterators to array
                if (typeof arrayLike.length === 'undefined' && typeof arrayLike.next === 'function') {
                    var iter;
                    var arrayLikeItems = [];

                    while (iter = arrayLike.next()) {
                        if (iter.done) {
                            break;
                        }
                        arrayLikeItems.push(iter.value);
                    }
                    return arrayLikeItems;
                }

                // 1. Let C be the this value.
                var C = this;

                // 2. Let items be ToObject(arrayLike).
                var items = Object(arrayLike);

                // 3. ReturnIfAbrupt(items).
                if (arrayLike == null) {
                    throw new TypeError('Array.from requires an array-like object - not null or undefined');
                }

                // 4. If mapfn is undefined, then let mapping be false.
                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                        throw new TypeError('Array.from: when provided, the second argument must be a function');
                    }

                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                        T = arguments[2];
                    }
                }

                // 10. Let lenValue be Get(items, "length").
                // 11. Let len be ToLength(lenValue).
                var len = toLength(items.length);

                // 13. If IsConstructor(C) is true, then
                // 13. a. Let A be the result of calling the [[Construct]] internal method
                // of C with an argument list containing the single item len.
                // 14. a. Else, Let A be ArrayCreate(len).
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                // 16. Let k be 0.
                var k = 0;
                // 17. Repeat, while k < len… (also steps a - h)
                var kValue;
                while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                        A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                        A[k] = kValue;
                    }
                    k += 1;
                }
                // 18. Let putStatus be Put(A, "length", len, true).
                A.length = len;
                // 20. Return A.
                return A;
            };
        }());
    }

    if (!Array.prototype.includes) {
        // https://tc39.github.io/ecma262/#sec-array.prototype.includes
        function sameValueZero(x, y) {
            return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
        }

        Object.defineProperty(Array.prototype, 'includes', {
            value: function (valueToFind, fromIndex) {
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                // 1. Let O be ? ToObject(this value).
                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If len is 0, return false.
                if (len === 0) {
                    return false;
                }

                // 4. Let n be ? ToInteger(fromIndex).
                //    (If fromIndex is undefined, this step produces the value 0.)
                var n = fromIndex | 0;

                // 5. If n ≥ 0, then
                //  a. Let k be n.
                // 6. Else n < 0,
                //  a. Let k be len + n.
                //  b. If k < 0, let k be 0.
                var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

                // 7. Repeat, while k < len
                while (k < len) {
                    // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                    // b. If SameValueZero(valueToFind, elementK) is true, return true.
                    if (sameValueZero(o[k], valueToFind)) {
                        return true;
                    }
                    // c. Increase k by 1.
                    k++;
                }

                // 8. Return false
                return false;
            }
        });
    }

    function isMapShouldBePolyfilled() {
        var map;
        if (typeof Map !== 'function') return true;
        try {
            map = new Map([[1, 2], [3, 4]]);
        } catch (e) {
            return true;
        }
        if (String(map) !== '[object Map]') return true;
        if (map.size !== 2) return true;
        if (typeof map.clear !== 'function') return true;
        if (typeof map.delete !== 'function') return true;
        if (typeof map.entries !== 'function') return true;
        if (typeof map.forEach !== 'function') return true;
        if (typeof map.get !== 'function') return true;
        if (typeof map.has !== 'function') return true;
        if (typeof map.keys !== 'function') return true;
        if (typeof map.set !== 'function') return true;
        if (typeof map.values !== 'function') return true;

        return false;
    }

    function isSetShouldBePolyfilled() {
        var set;
        if (typeof Set !== 'function') return true;
        try {
            set = new Set([1, 2, 3, 4]);
        } catch (e) {
            return true;
        }
        if (String(set) !== '[object Set]') return true;
        if (set.size !== 4) return true;
        if (typeof set.clear !== 'function') return true;
        if (typeof set.delete !== 'function') return true;
        if (typeof set.entries !== 'function') return true;
        if (typeof set.forEach !== 'function') return true;
        if (typeof set.has !== 'function') return true;
        if (typeof set.add !== 'function') return true;
        if (typeof set.values !== 'function') return true;

        return false;
    }

    var indexOf = function(value) {
        if(value != value || value === 0) {
            for(var i = this.length; i-- && !is(this[i], value);){}
        } else {
            i = [].indexOf.call(this, value);
        }
        return i;
    };

    var is = Object.is || function(a, b) {
        return a === b ?
            a !== 0 || 1 / a == 1 / b :
            a != a && b != b;
    };

    if (isMapShouldBePolyfilled()) {
        (function () {
            function MapIterator(map, kind) {
                var _index = 0;

                return Object.create({}, {
                    next: {
                        value: function() {
                            if (_index < map.size) {
                                switch(kind) {
                                    case 'keys': return {
                                        value: map._keys()[_index++],
                                        done: false
                                    }
                                    case 'values': return {
                                        value: map._values()[_index++],
                                        done: false
                                    };
                                    case 'keys+values': return {
                                        value: map._entries()[_index++],
                                        done: false
                                    };
                                    default: throw new TypeError('Invalid iterator type');
                                }
                            }
                            return {
                                value: undefined,
                                done: true
                            }
                        }
                    },
                    toString: {
                        value: function() {
                            return '[object Map Iterator]';
                        }
                    }
                });
            }

            function MapShim(iterable) {
                var _entries  = [];
                var _keys   = [];
                var _values = [];

                var _set = function(key, value) {
                    // check if key exists and overwrite
                    var index = indexOf.call(_keys, key);
                    if (index > -1) {
                        _entries[index][1] = value;
                        _values[index] = value;
                    } else {
                        _entries.push([key, value]);
                        _keys.push(key);
                        _values.push(value);
                    }
                };

                var _setItem = function(item) {
                    if (item.length !== 2) {
                        throw new TypeError('Invalid iterable passed to Map constructor');
                    }

                    _set(item[0], item[1]);
                };

                if (Array.isArray(iterable)) {
                    iterable.forEach(_setItem)
                } else if (iterable instanceof MapShim) {
                    iterable.forEach(function (value, key) {
                        return _setItem([key, value])
                    })
                }

                return Object.create(MapShim.prototype, {
                    _entries: {
                        value: function() {
                            return [].slice.call(_entries);
                        }
                    },
                    entries: {
                        value: function() {
                            return new MapIterator(this, 'keys+values')
                        }
                    },
                    _keys: {
                        value: function() {
                            return [].slice.call(_keys);
                        }
                    },
                    keys: {
                        value: function() {
                            return new MapIterator(this, 'keys')
                        }
                    },
                    _values: {
                        value: function() {
                            return [].slice.call(_values);
                        }
                    },
                    values: {
                        value: function() {
                            return new MapIterator(this, 'values')
                        }
                    },
                    has: {
                        value: function(key) {
                            var index = indexOf.call(_keys, key);
                            return index > -1;
                        }
                    },
                    get: {
                        value: function(key) {
                            var index = indexOf.call(_keys, key);
                            return index > -1 ? _values[index] : undefined;
                        }
                    },
                    set: {
                        value: _set
                    },
                    size: {
                        get: function() {
                            return _entries.length;
                        }
                    },
                    clear: {
                        value: function() {
                            _keys.length = _values.length = _entries.length = 0;
                        }
                    },
                    'delete': {
                        value: function(key) {
                            var index = indexOf.call(_keys, key);
                            if (index > -1) {
                                _keys.splice(index, 1);
                                _values.splice(index, 1);
                                _entries.splice(index, 1);
                                return true;
                            }
                            return false;
                        }
                    },
                    forEach: {
                        value: function(callbackfn /*, thisArg*/) {
                            if (typeof callbackfn !== 'function') {
                                throw new TypeError('Invalid callback function given to forEach');
                            }

                            var self = this;

                            _entries.forEach(function(current) {
                                callbackfn.apply(arguments[1], [current[1], current[0], self])
                            })
                        }
                    },
                    toString: {
                        value: function() {
                            return '[Object Map]';
                        }
                    }
                });
            }

            MapShim.prototype = new MapShim();
            MapShim.prototype.constructor = MapShim;

            window.Map = MapShim
        })();
    }

    if (isSetShouldBePolyfilled()) {
        (function () {
            function SetIterator(set, kind) {
                var _index = 0;

                return Object.create({}, {
                    next: {
                        value: function() {
                            if (_index < set.size) {
                                switch(kind) {
                                    case 'values': return {
                                        value: set._values()[_index++],
                                        done: false
                                    };
                                    case 'keys+values': return {
                                        value: set._entries()[_index++],
                                        done: false
                                    };
                                    default: throw new TypeError('Invalid iterator type');
                                }
                            }
                            return {
                                value: undefined,
                                done: true
                            }
                        }
                    },
                    toString: {
                        value: function() {
                            return '[object Set Iterator]';
                        }
                    }
                });
            }

            function SetShim(iterable) {
                var _entries  = [];
                var _values = [];

                var _add = function(value) {
                    // check if key exists and overwrite
                    var index = indexOf.call(_values, value);
                    if (index > -1) {
                        _entries[index][1] = value;
                        _values[index] = value;
                    } else {
                        _entries.push([value, value]);
                        _values.push(value);
                    }
                };

                if (Array.isArray(iterable) || iterable instanceof SetShim) {
                    iterable.forEach(_add);
                }

                return Object.create(SetShim.prototype, {
                    _entries: {
                        value: function() {
                            return [].slice.call(_entries);
                        }
                    },
                    entries: {
                        value: function() {
                            return new SetIterator(this, 'keys+values')
                        }
                    },
                    _values: {
                        value: function() {
                            return [].slice.call(_values);
                        }
                    },
                    values: {
                        value: function() {
                            return new SetIterator(this, 'values')
                        }
                    },
                    keys: {
                        value: function() {
                            return new SetIterator(this, 'values')
                        }
                    },
                    has: {
                        value: function(value) {
                            var index = indexOf.call(_values, value);
                            return index > -1;
                        }
                    },
                    add: {
                        value: _add
                    },
                    size: {
                        get: function() {
                            return _entries.length;
                        }
                    },
                    clear: {
                        value: function() {
                            _values.length = _entries.length = 0;
                        }
                    },
                    'delete': {
                        value: function(value) {
                            var index = indexOf.call(_values, value);
                            if (index > -1) {
                                _values.splice(index, 1);
                                _entries.splice(index, 1);
                                return true;
                            }
                            return false;
                        }
                    },
                    forEach: {
                        value: function(callbackfn /*, thisArg*/) {
                            if (typeof callbackfn !== 'function') {
                                throw new TypeError('Invalid callback function given to forEach');
                            }

                            var self = this;

                            _entries.forEach(function(current) {
                                callbackfn.apply(arguments[1], [current[1], current[0], self])
                            })
                        }
                    },
                    toString: {
                        value: function() {
                            return '[Object Set]';
                        }
                    }
                });
            }

            SetShim.prototype = new SetShim();
            SetShim.prototype.constructor = SetShim;

            window.Set = SetShim;
        })();
    }
}());

window.MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

/*
 * Полифил для AbortController
 */
(function (self) {
    var isNeeded = (typeof(self.Request) === 'function' && !self.Request.prototype.hasOwnProperty('signal')) ||
                    !self.AbortController;

    if (!isNeeded) {
        return;
    }

    function AbortSignal() {
        // Compared to assignment, Object.defineProperty makes properties non-enumerable by default and
        // we want Object.keys(new AbortController().signal) to be [] for compat with the native impl

        Object.defineProperty(this, 'listeners', { value: {}, writable: true, configurable: true });
        Object.defineProperty(this, 'aborted', { value: false, writable: true, configurable: true });
        Object.defineProperty(this, 'onabort', { value: null, writable: true, configurable: true });
    }

    AbortSignal.prototype = {

        addEventListener: function(type, callback) {
            if (!(type in this.listeners)) {
                this.listeners[type] = [];
            }
            this.listeners[type].push(callback);
        },

        dispatchEvent: function(event) {
            if (event.type === 'abort') {
                this.aborted = true;
                if (typeof this.onabort === 'function') {
                    this.onabort.call(this, event);
                }
            }

            if (!(event.type in this.listeners)) {
                return;
            }

            var stack = this.listeners[event.type];
            for (var i = 0, l = stack.length; i < l; i++) {
                stack[i](event);
            }
            return !event.defaultPrevented;
        },

        removeEventListener: function(type, callback) {
            if (!(type in this.listeners)) {
                return;
            }
            var stack = this.listeners[type];
            for (var i = 0, l = stack.length; i < l; i++) {
                if (stack[i] === callback) {
                    stack.splice(i, 1);
                    return;
                }
            }
        },

        toString: function() {
            return '[object AbortSignal]';
        }
    }


    function AbortController() {
        // Compared to assignment, Object.defineProperty makes properties non-enumerable by default and
        // we want Object.keys(new AbortController()) to be [] for compat with the native impl
        Object.defineProperty(this, 'signal', { value: new AbortSignal(), writable: true, configurable: true });
    }

    AbortController.prototype = {
        abort: function() {
            var event;
            try {
                event = new Event('abort');
            } catch (e) {
                // For Internet Explorer 11:
                event = document.createEvent('Event');
                event.initEvent('abort', false, false);
            }

            this.signal.dispatchEvent(event);
        },
        toString: function() {
            return '[object AbortController]';
        }
    }

    if (typeof Symbol !== 'undefined' && Symbol.toStringTag) {
        // These are necessary to make sure that we get correct output for:
        // Object.prototype.toString.call(new AbortController())
        AbortController.prototype[Symbol.toStringTag] = 'AbortController';
        AbortSignal.prototype[Symbol.toStringTag] = 'AbortSignal';
    }

    self.AbortController = AbortController;
    self.AbortSignal = AbortSignal;

})(window);

/*
В современных системах для наследования классов используется Reflect,
но в некоторых браузерах (FF 42-43) он не работает корректно.

Бабель же для проверки использования Reflect читает поле Reflect.construct.sham:
оно будет true, если Reflect не работает.

Но бабель почему-то не в курсе, что Reflect.construct кое-где работает неправильно, поэтому мы проверяем сами,
и, если не очень, тоже выставлем это поле
*/
(function () {
    if (typeof Reflect === "undefined" || !Reflect.construct) return;

    /* Проверим, функционирует ли Reflect.construct с 3мя пар-ми  */
    function A() {
    }

    function B() {
    }

    if (!(Reflect.construct(A, [], B) instanceof B)) {
        /* НЕ работает */
        Reflect.construct.sham = 1;
    }
})();

// Object.entries
if (!Object.entries) {
    Object.defineProperty(Object, 'entries', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function(target) {
            'use strict';
            return Object.keys(target).map(function(key) {
                return [key, target[key]];
            });
        }
    });
}

(function(global) {
    var NativePromise = global['Promise'];
    var nativePromiseSupported =
        NativePromise
        // Some of these methods are missing from
        // Firefox/Chrome experimental implementations
        && 'resolve' in NativePromise
        && 'reject' in NativePromise
        && 'all' in NativePromise
        && 'allSettled' in NativePromise
        && 'any' in NativePromise
        && 'race' in NativePromise
        && 'finally' in NativePromise.prototype
        && (function () {
            // Older version of the spec had a resolver object
            // as the arg rather than a function
            var resolve;
            new NativePromise(function (r) {
                resolve = r;
            });
            return typeof resolve === 'function';
        })();

    if (!nativePromiseSupported) {
        // Store setTimeout reference so promise-polyfill will be unaffected by
        // other code modifying setTimeout (like sinon.useFakeTimers())
        var setTimeoutFunc = setTimeout;

        function noop() {}

        // Use polyfill for setImmediate for performance gains
        var asap = (typeof setImmediate === 'function' && setImmediate) ||
            function(fn) { setTimeoutFunc(fn, 1); };

        var isArray = Array.isArray || function(value) { return Object.prototype.toString.call(value) === "[object Array]" };

        function reverse(promise) {
            return new Promise(function (resolve, reject) {
                return Promise.resolve(promise).then(reject, resolve);
            })
        }

        /**
         * @param fn
         * @constructor
         */
        function Promise(fn) {
            if (typeof this !== 'object') throw new TypeError('Promises must be constructed via new');
            if (typeof fn !== 'function') throw new TypeError('not a function');
            this._state = 0;
            this._value = undefined;
            this._deferreds = [];

            doResolve(fn, this)
        }

        function handle(self, deferred) {
            while (self._state === 3) {
                self = self._value;
            }
            if (self._state === 0) {
                self._deferreds.push(deferred);
                return
            }
            asap(function() {
                var cb = self._state === 1 ? deferred.onFulfilled : deferred.onRejected
                if (cb === null) {
                    (self._state === 1 ? resolve : reject)(deferred.promise, self._value);
                    return;
                }
                var ret;
                try {
                    ret = cb(self._value);
                } catch (e) {
                    reject(deferred.promise, e);
                    return;
                }
                resolve(deferred.promise, ret);
            })
        }

        function resolve(self, newValue) {
            try { //Promise Resolution Procedure: https://github.com/promises-aplus/promises-spec#the-promise-resolution-procedure
                if (newValue === self) throw new TypeError('A promise cannot be resolved with itself.');
                if (newValue && (typeof newValue === 'object' || typeof newValue === 'function')) {
                    var then = newValue.then;
                    if (newValue instanceof Promise) {
                        self._state = 3;
                        self._value = newValue;
                        finale(self);
                        return;
                    } else if (typeof then === 'function') {
                        doResolve(then.bind(newValue), self);
                        return;
                    }
                }
                self._state = 1;
                self._value = newValue;
                finale(self);
            } catch (e) { reject(self, e); }
        }

        function reject(self, newValue) {
            self._state = 2;
            self._value = newValue;
            finale(self);
        }

        function finale(self) {
            for (var i = 0, len = self._deferreds.length; i < len; i++) {
                handle(self, self._deferreds[i]);
            }
            self._deferreds = null;
        }

        function Handler(onFulfilled, onRejected, promise){
            this.onFulfilled = typeof onFulfilled === 'function' ? onFulfilled : null;
            this.onRejected = typeof onRejected === 'function' ? onRejected : null;
            this.promise = promise;
        }

        /**
         * Take a potentially misbehaving resolver function and make sure
         * onFulfilled and onRejected are only called once.
         *
         * Makes no guarantees about asynchrony.
         */
        function doResolve(fn, self) {
            var done = false;
            try {
                fn(function (value) {
                    if (done) return;
                    done = true;
                    resolve(self, value);
                }, function (reason) {
                    if (done) return;
                    done = true;
                    reject(self, reason);
                })
            } catch (ex) {
                if (done) return;
                done = true;
                reject(self, ex);
            }
        }

        Promise.prototype.catch = function (onRejected) {
            return this.then(null, onRejected);
        };

        Promise.prototype.then = function(onFulfilled, onRejected) {
            var prom = new Promise(noop);
            handle(this, new Handler(onFulfilled, onRejected, prom));
            return prom;
        };

        Promise.all = function () {
            var args = Array.prototype.slice.call(arguments.length === 1 && isArray(arguments[0]) ? arguments[0] : arguments);

            return new Promise(function (resolve, reject) {
                if (args.length === 0) return resolve([]);
                var remaining = args.length;
                function res(i, val) {
                    try {
                        if (val && (typeof val === 'object' || typeof val === 'function')) {
                            var then = val.then;
                            if (typeof then === 'function') {
                                then.call(val, function (val) { res(i, val) }, reject);
                                return;
                            }
                        }
                        args[i] = val;
                        if (--remaining === 0) {
                            resolve(args);
                        }
                    } catch (ex) {
                        reject(ex);
                    }
                }
                for (var i = 0; i < args.length; i++) {
                    res(i, args[i]);
                }
            });
        };

        Promise.resolve = function (value) {
            if (value && typeof value === 'object' && value.constructor === Promise) {
                return value;
            }

            return new Promise(function (resolve) {
                resolve(value);
            });
        };

        Promise.reject = function (value) {
            return new Promise(function (resolve, reject) {
                reject(value);
            });
        };

        Promise.race = function (values) {
            return new Promise(function (resolve, reject) {
                for(var i = 0, len = values.length; i < len; i++) {
                    values[i].then(resolve, reject);
                }
            });
        };

        Promise.allSettled = function () {
            var args = Array.prototype.slice.call(arguments.length === 1 && isArray(arguments[0]) ? arguments[0] : arguments);

            return Promise.all(args.map(function (promise) {
                return promise
                    .then(function (value) {
                        return {
                            status: 'fulfilled',
                            value: value
                        }
                    })
                    .catch(function (reason) {
                        return {
                            status: 'rejected',
                            reason: reason
                        }
                    })
            }))
        }

        Promise.any = function () {
            var args = Array.prototype.slice.call(arguments.length === 1 && isArray(arguments[0]) ? arguments[0] : arguments);

            return reverse(Promise.all(args.map(reverse)));
        }

        Promise.prototype.finally = function (fn) {
            function onFinally(callback) {
                return Promise.resolve(fn()).then(callback);
            }

            return this.then(
                function (result) {
                    return onFinally(function () {
                        return result
                    })
                },
                function (reason) {
                    return onFinally(function () {
                        return Promise.reject(reason)
                    })
                }

            )
        }

        /**
         * Set the immediate function to execute callbacks
         * @param fn {function} Function to execute
         * @private
         */
        Promise._setImmediateFn = function _setImmediateFn(fn) {
            asap = fn;
        };

        if (typeof module !== 'undefined' && module.exports) {
            module.exports = Promise;
        } else {
            global.Promise = Promise;
        }
    }
})(typeof window != 'undefined' ? window : typeof global != 'undefined' ? global : typeof self != 'undefined' ? self : this);
